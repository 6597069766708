<template>
  <div class="picture-card">
    <div class="card shadow-sm mb-5 bg-body rounded">
      <img :src="getImageSource(data.imgSrc)"
           class="card-img-top card-image-top-max"
           :class="{'_inactive': inactive}"
           :alt="data.imgAlt">
      <div class="card-body position-relative text-center d-flex flex-column justify-content-between" v-if="!inactive">
        <div class="card-name" :class="{'_inactive': inactive}">{{ data.name }}
        </div>
        <div class="card-text text-start">
          <p>{{data.title}}</P>
          <template v-if="notStarted">
            <img src="@/assets/icons/android-checkbox-outline.svg" height="28px" class="invertColor">{{ questionsCount }} Fragen
          </template>
          <template v-else>
            <div class="progress">
              <div class="progress-bar bg-success" role="progressbar" :style="'width: '+ questionAnswered[2] +'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div>
              {{ questionAnswered[0] }} / {{ questionAnswered[1] }}
            </div>
          </template>
        </div>
        <div>
          <button class="btn btn-green text-uppercase" @click="link">Übung Starten</button>
        </div>
      </div>
      <div class="card-body position-relative text-center d-flex flex-column justify-content-between" v-else>
        <div class="card-name" :class="{'_inactive': inactive}">
          {{ data.name }}
        </div>
        <div class="card-text text-start">
          <p>{{data.title}}</P>
        </div>
      </div>
    </div>

    <!-- modal -->

    <b-modal :id="'return_' + data.id"  hide-footer size="sm" centered>
      <template #modal-header>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h4>Übung Fortsetzen?</h4>
            </div>
          </div>
        </div>
      </template>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            Sie können die Übung fortsetzen oder neu starten.
          </div>
          <div class="col-12 d-flex justify-content-between mt-3">
            <button class="btn btn-outline-dark py-3 w-75 mx-2" @click="startNew()">Neu starten</button>
            <button class="btn btn-success py-3 w-75 " @click="continueQuestions()">Fortsetzen</button>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "picture-card",
  props: ['data', 'path', 'demoMode', 'index'],
  data() {
    return {
      questionsCount: 0,
      error: []
    }
  },
  methods: {
    getImageSource(img_src) {
      if (img_src.includes('upload_')) {
        return this.$store.state.partImages  + 'uploads/' + img_src
      }

      return this.$store.state.partImages + this.path + '/' + this.data.front_id + '/' +img_src
    },
    link() {
      localStorage.setItem('actualFrontId', this.data.frontId);
      localStorage.setItem('actualPartName', this.data.name + " - " + this.data.title);

      this.$store.commit('setLastPart', {
        name: this.$store.getters.getArea,
        id: this.index
      });

      if(localStorage.getItem('lecture_' + this.path + '_' + this.data.id + '_started') == 'true') {
        this.$bvModal.show('return_'+this.data.id);
      } else {
        this.$router.push({ name: 'questions', params: { part: this.data.id, reload: true }})
      }
    },
    startNew() {
      localStorage.setItem('actualFrontId', this.data.frontId);
      this.$router.push({ name: 'questions', params: { part: this.data.id, reload: true }});
    },
    continueQuestions() {
      localStorage.setItem('actualFrontId', this.data.frontId);
      this.$router.push({ name: 'questions', params: { part: this.data.id, reload: false }})
    },
    getQuestionCount() {
      if (!this.inactive) {
        axios.get(this.$store.state.apiPath + '/questions/', { params: { part_id: this.data.id }})
            .then(response => {
              if(response.data) {
                this.questionsCount = response.data["hydra:member"].length
              }
            })
            .catch(e => {
              this.error.push(e)
              this.textfieldError = true;
            });
      }
    }
  },
  computed: {
    notStarted() {
      if(localStorage.getItem('lecture_' + this.path + '_' + this.data.id + '_started') == 'true') {
        return false
      } else {
        return true
      }
    },
    inactive() {
      if (this.data.front_id === "partDemo") {
        return false
      } else if (this.data.front_id !== "partDemo" && !this.demoMode) {
        return false
      } else {
        return true
      }
    },
    questionAnswered() {
      let counter = 0;
      let length = JSON.parse(localStorage.getItem('lecture_' + this.path + '_' + this.data.id)).length
      // TODO bearbeitete fragen anzeigen
      for (let i = 0; i < length; i++) {
        try {
          if (JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.data.id + '_' + 'questions_' + i)).edited == true) {
            counter ++
          }
        } catch (e) {
          //console.error(e)
        }
      }
      let percent = 100 / length * counter
      let data = [counter, length, percent]
      return data
    }
  },
  mounted() {
    this.getQuestionCount()
  }
}
</script>

<style lang="scss" scoped>
.picture-card {
  height: 100%;

  .card {
    width: 600px;
    height: 100%;

    .card-image-top-max {
      max-height: 370px;
      min-width: 320px;
    }
    .card-img-top._inactive {
      opacity: .4;
    }

    .card-body {
      height: 200px;
      .card-name {
        position: absolute;
        text-align: center;
        top: -50px;
        left: 0;
        background-color: #fff;
        width: 120px;
        height: 55px;
        padding: 10px 5px;
        font-size: 1.5rem;
        font-weight: bold;

        &._inactive {
          background-color: rgba(255,255,255,.6);
        }
      }
      .card-text {

      }
      .btn {
        padding: 10px 0;
        width: 230px;
      }
      .btn-green {
        background-color: #008641;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 400px){
  .picture-card {
    .card {
      width: 325px;
    }
  }
}

.darkmode {
  .picture-card {
    .card {
      .card-body {
        background-color: #000;
        color: #fff;

        .invertColor {
          filter: invert(100%);
        }
        .card-name {
          background-color: #000;
        }
      }
    }
  }
}

</style>
