import Vue from 'vue';
import VueRouter from 'vue-router';
import berufsbilder from '../views/berufsbilder.vue';
import parts from '../views/parts.vue';
import questions from '../views/questions.vue';
import NotFound from '../views/NotFound.vue';
import tour from '../views/tour.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'berufsbilder'}
  },
  {
    path: '/:params',
    name: 'Home_params',
    redirect: { name: 'berufsbilder'}
  },
  {
    path: '/berufsbilder',
    name: 'berufsbilder',
    component: berufsbilder
  },
  {
    path: '/tour',
    name: 'tour',
    component: tour
  },
  {
    path: '/lecture/:id',
    name: 'parts',
    component: parts
  },
  {
    path: '/lecture/:id/:part',
    name: 'questions',
    component: questions
  },
  {
    path: '/:notFound(.*)',
    name: 'notFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
