<template>
  <div :class="{'darkmode': darkmode, 'bigmode': fontSize}">
    <template v-if="!settings && checkVisibility">
      <the-header></the-header>
      <div class="spacer"></div>
    </template>
    <settings v-show="settings"></settings>
    <router-view v-show="!settings" ></router-view>
  </div>
</template>

<script>
import TheHeader from './components/layout/layout-header';
import Settings from "@/components/settings/settings";
import axios from "axios";
import qs from "querystring";

export default {
  components: {Settings, TheHeader},
  data() {
    return {
      userActive: true
    }
  },
  beforeMount() {
    let settings = {
      'size': 'big',
      'mode': 'light'
    };

    this.$store.commit('showHeadbar', true);

    if (!localStorage.getItem('settings')) {
      localStorage.setItem('settings', JSON.stringify(settings))
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('t')) {
          axios.post(this.$store.state.tokenValidator, qs.stringify({
            token: urlParams.get('t')
          })).then((res) => {
            if (res.data) {
              localStorage.clear();
              localStorage.setItem('userToken', urlParams.get('t'));
              window.location.href = "/berufsbilder";
            }
          }).catch(()=> {
            window.location.href = "/berufsbilder";
          })
    }

    settings = JSON.parse(localStorage.getItem('settings'));
    this.$store.commit('setDesign', settings)
  },
  methods: {
    resetTimer() {
      this.userActive = false
    }
  },
  mounted() {
    let userInactiveInterval = 20000;
    let movedTimer = setInterval(this.resetTimer, userInactiveInterval)

    document.addEventListener("mousemove", () => {
      this.userActive = true

      clearInterval(movedTimer);
      movedTimer = setInterval(this.resetTimer, userInactiveInterval);
    });

    document.addEventListener("keypress", () => {
      this.userActive = true
      clearInterval(movedTimer);
      movedTimer = setInterval(this.resetTimer, userInactiveInterval);
    });


    setInterval(()=>{
      if (this.userActive) {
        const today = new Date()

        let data = {
          entry: today,
          path: this.$route.fullPath,
        }

        let trackingTime = JSON.parse(localStorage.getItem('time'));

        if (trackingTime) {
          trackingTime.data.push(data)
          localStorage.setItem('time', JSON.stringify(trackingTime))

          if (trackingTime.data.length > 29) {
            if (localStorage.getItem('userToken')) {
              axios.post(`${process.env.VUE_APP_BASE_API_URL}api/tracking_times`,{
                StudentId: localStorage.getItem('userToken'),
                date: today,
                data: [JSON.parse(localStorage.getItem('time'))]
              })
            }
            localStorage.removeItem('time')
          }

        } else {
          localStorage.setItem('time', JSON.stringify({"data": [data]}))

        }
      }
    },2000)
  },
  computed: {
    settings() {
      return this.$store.getters.getShowSettings
    },
    darkmode() {
      let design = this.$store.getters.getDesign;
      if (design.mode == 'dark') {
        return true
      }
      return false
    },
    fontSize() {
      let design = this.$store.getters.getDesign;
      if (design.size == 'big') {
        return true
      }
      return false
    },
    checkVisibility() {
      if (this.$store.getters.getHeadbar) {
       return true
      }
      return false
    }
  }
};
</script>


<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  max-height: 100vh;
  margin: 0;
}

.spacer {
  padding-top: 5rem;
}

.darkmode {
  background-color: #000;
}

[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.bigmode {
  font-size: 24px;

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 35px;
  }

  p {
    font-size: 24px;
  }

  .modal-content {
    background-color: red !important;
  }
}

.pointer {
  cursor: pointer !important;
}

</style>
