<template>
  <div class="index" :class="{'darkmode': darkmode}">
    <div class="container">
      <div class="row">
        <div class="col-12" v-for="berufsbild in lectures" :key="berufsbild.id">
          <picture-frame :data="berufsbild"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PictureFrame from "@/components/berufsbilder/picture-frame";

export default {
  name: 'berufsbilder',
  components: {PictureFrame},
  data() {
    return {
      textfieldError: false,
      lectures: {}
    }
  },
  computed: {
    darkmode() {
      let design = this.$store.getters.getDesign;
      if (design.mode == 'dark') {
        return true
      }
      return false
    },
  },
  methods: {
    updateBerufsbilder() {
      if (axios.get(this.$store.state.apiPath + '/jobfields')) {
        axios.get(this.$store.state.apiPath + '/jobfields')
            .then(response => {
              if(response.data) {
                this.lectures = response.data["hydra:member"];
                localStorage.setItem('lectures', JSON.stringify(response.data["hydra:member"]));
              }
            })
            .catch(e => {
              this.error.push(e)
            });
      } else {
        if (localStorage.getItem('lectures')) {
          this.lectures = JSON.parse(localStorage.getItem('lectures'));
        } else {
          this.textfieldError = true;
        }
      }
    },
  },
  beforeMount() {
    this.$store.commit('changeArea', 'Berufsbilder')
    this.updateBerufsbilder()
  }
};
</script>

<style scoped lang="scss">
.darkmode {
  background-color: #000;
}

</style>
