<template>
  <div class="parts d-flex">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <template v-if="isReady">
            <VueSlickCarousel ref="parts_carousel" v-bind="settings">
              <div v-for="(part, index) in parts" :key="part.id">
                <picture-card :data="part" :path="$route.params.id" :index="index" :demoMode="demoMode"></picture-card>
              </div>
            </VueSlickCarousel>
          </template>
        </div>
      </div>
    </div>
    <div class="bottom-bar">
      <div class="container">
        <div class="row">
          <div class="buttons col-12 d-flex justify-content-between">
            <button @click="showPrev" class="back btn"><img src="@/assets/icons/android-arrow-back.svg" height="35px"></button>
            <button class="btn btn-outline-dark text-uppercase py-1 btn-start-test" @click="testSettings()">Test starten <img src="@/assets/icons/android-clipboard.svg" height="30px"></button>
            <button @click="showNext" class="back btn"><img src="@/assets/icons/android-arrow-forward.svg" height="35px"></button>
          </div>
        </div>
      </div>
    </div>

    <!-- modals -->
    <b-modal id="startTest" hide-footer hide-header size="lg" centered scrollable>
      <div class="container-fluid">
        <div class="row auswertung-inner">
          <div class="col-12 text-end">
            <button @click="$bvModal.hide('startTest')" class="btn btn-c" style="font-size: 30px">&#x2715;</button>
          </div>
          <div class="col-12 text-center">
            <img src="@/assets/icons/university.svg" class="green-filter university-icon mb-3">
          </div>
          <div class="col-12 text-center mb-4">
            <button class="btn btn-modal btn-success text-uppercase py-3 w-50" style="font-size: 16px" @click="startTest">Test beginnen</button>
          </div>

          <div class="col-12 bg-settings">
            <b-card-group>
              <b-card footer-tag="footer">
                <template #header>
                  <div class="row">
                    <div class="col-8">
                      Inhalte anpassen
                    </div>
                    <div class="col-4">
                      <label class="switch">
                        <input type="checkbox" v-model="customiseContent">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </template>

                <div v-for="part in testPartList" :key="part.id" v-show="customiseContent">
                  <div class="row">
                    <div class="col-2">
                      <input type="checkbox" v-model="part.checked" :name="part.id" @click="changeChosenParts()" class="input-box my-3">
                    </div>
                    <div class="col-10 justify-content-center d-flex flex-column">
                      <label :for="part.partId">
                        <span class="fw-bold">{{ part.part }}: </span> {{ part.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="slidecontainer">
                  <p>Anzahl der Fragen anpassen</p>
                  <input type="number" min="1" :max="sliderMax" v-model="howManyQuestions" class="green-slider"> / {{ sliderMax }}
                </div>
              </b-card>
            </b-card-group>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import PictureCard from "../components/parts/picture-card";

import qs from "querystring"

export default {
  name: "parts",
  components: {PictureCard, VueSlickCarousel },
  data() {
    return {
      settings: {
        "dots": false,
        "arrows": false,
        "focusOnSelect": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "centerMode": false,
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": false,
              "dots": false,
            }
          },
          {
            "breakpoint": 1600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
              "infinite": false,
              "dots": false,
              "centerMode": false
            }
          }
        ]
      },
      parts: {},
      isReady: false,
      testPartList: [],
      chosenParts: [''],
      testQuestions: [],
      howManyQuestions: 0,
      allQuestions: [],
      customiseContent: false,
      demoMode: true,
      error: [],
      scrollTimerCount: 0
    }
  },
  watch: {
    customiseContent() {
      this.changeChosenParts();
    }
  },
  methods: {
    showDemo(name) {
      let temp = true;
      if (!this.demoMode) {
        if (name === 'partDemo') {
          temp = false
        }
      }
      return temp
    },
    updateBerufsbilder() {
      let jobfieldId = this.$route.params.jobfieldId;
      let path = this.$route.params.id;

      if (jobfieldId === undefined) {
        let lectures = JSON.parse(localStorage.getItem('lectures'))
        jobfieldId = lectures.filter((e) => {
          return e.front_id.includes(path)
        })[0].id
      }


        axios.get(this.$store.state.apiPath + '/parts'+ '?jobfield_id=' + jobfieldId)
            .then(response => {
              if(response.data) {
                response.data["hydra:member"] = response.data["hydra:member"].filter((e) => {
                  let id = e.jobfieldId.split('/').pop()
                  return id == jobfieldId
                })

                axios.post(this.$store.state.tokenValidator, qs.stringify({
                  token: localStorage.getItem('userToken')
                })).then((res) => {
                  if (res.data.includes(parseInt(response.data['hydra:member'][0].jobfieldId.split("/").pop()))) {
                    localStorage.setItem('lecture_' + path, JSON.stringify(response.data["hydra:member"]))
                    this.parts = response.data["hydra:member"]

                    this.parts = this.parts.filter((e) => {
                      return !e.frontId.includes("Demo")
                    })

                    this.demoMode = false;
                    this.isReady = true;

                    this.listParts();
                  } else {
                    localStorage.setItem('lecture_' + path, JSON.stringify(response.data["hydra:member"][0]))
                    this.parts = response.data["hydra:member"]
                    this.demoMode = true;
                    this.isReady = true;
                    this.listParts();
                  }
                }).catch((e) => {
                  localStorage.setItem('lecture_' + path, JSON.stringify(response.data["hydra:member"][0]))
                  this.parts = response.data["hydra:member"]
                  this.demoMode = true;
                  this.isReady = true;
                  this.listParts();
                  this.error.push(e)
                })

              }
            })
            .catch(e => {
              this.error.push(e)
            });
    },
    listParts() {
      if (!this.demoMode) {
        for (let i = 0; i < this.parts.length; i++) {
          let temp = {};
          temp.part = this.parts[i].name;
          temp.name = this.parts[i].title;
          temp.partId = this.parts[i].id;
          temp.checked = true;
          this.testPartList.push(temp)
        }
      } else {
        let temp = {};
        temp.part = this.parts[0].name;
        temp.name = this.parts[0].title;
        temp.partId = this.parts[0].id;
        temp.checked = true;
        this.testPartList.push(temp)
      }

    },
    showPrev() {
      window.speechSynthesis.cancel();
      this.$refs.parts_carousel.prev();
    },
    showNext() {
      window.speechSynthesis.cancel();
      this.$refs.parts_carousel.next();
    },
    goToSlide(id) {
      this.$refs.parts_carousel.goTo(id - 1);
    },
    testSettings() {
      this.changeChosenParts();
      this.$bvModal.show('startTest');
    },
    startTest() {
      let randomQuestions = [];


      if (this.chosenParts.length >= this.howManyQuestions) {
        this.chosenParts.sort(() => 0.5 - Math.random());
        while (randomQuestions.length < this.howManyQuestions) {
          randomQuestions.push(this.chosenParts[randomQuestions.length])
        }
      }


      this.$router.push({ name: 'questions', params: { part: "test", reload: true, testMode: true, questions: JSON.stringify(randomQuestions) }});
    },
    changeChosenParts() {
      this.chosenParts = [];
      let temp = [];
      let that = this;

      setTimeout(function(){
        for (let i = 0; i < that.testPartList.length; i++) {
          if (that.customiseContent) {
            if (that.testPartList[i].checked == true) {
              temp.push(that.testPartList[i]);
            }
          } else {
            temp.push(that.testPartList[i]);
          }
        }


        for (let i = 0; i < temp.length; i++) {
          let partIds = temp[i].partId
          axios.get(that.$store.state.apiPath + '/questions/', { params: { part_id: partIds }})
              .then(response => {
                if(response.data) {
                  for (let i = 0; i < response.data["hydra:member"].length; i++){
                    that.chosenParts.push(response.data["hydra:member"][i]);
                  }
                  that.howManyQuestions = that.sliderMax;
                }
              })
              .catch(e => {
                that.error.push(e)
              });
        }
      }, 80);
    },
  },
  computed: {
    sliderMax() {
      return (this.chosenParts.length > 100) ? 100 : this.chosenParts.length;
    }
  },
  mounted() {
    document.onkeydown = (event) => {
      if (event.keyCode == 39) {
        this.showNext()
      }
      if (event.keyCode == 37) {
        this.showPrev()
      }
    }
    setTimeout(() => {
      if (this.$store.state.lastPart.name === this.$store.getters.getArea) {
        this.goToSlide(this.$store.state.lastPart.id);
      }

      document.getElementsByClassName('parts')[0].addEventListener("wheel", event => {
          if (event.deltaY > 4) {
            this.showPrev()
          }
          if (event.deltaY < -3) {
            this.showNext()
          }
      });

    }, 1000)
  },
  beforeMount() {
    this.updateBerufsbilder();
    this.$store.commit('showHeadbar', true);
  }
}
</script>

<style lang="scss">
.parts {
  overflow: hidden;
  min-height: calc(100vh - 5rem);
  align-items: center;
  background-color: #F4F4F4;

  .bottom-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #ffffff;
    padding: 10px;
  }
}

.darkmode {
  .parts {
    background-color: #000;
    .bottom-bar {
      background-color: #000;
      .back {
        filter: invert(100%);
      }
      .btn-start-test {
        background-color: #fff !important;
      }
    }
  }
}

.university-icon {
  max-width: 220px;
  border-radius: 100%;
  border: 12px solid black;
  padding: 20px 20px;
}
.green-filter {
  filter: invert(45%) sepia(93%) saturate(334%) hue-rotate(97deg) brightness(91%) contrast(86%);
}
.bg-settings {
  background-color: #F4F4F4;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2C9961;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2C9961;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type=range] {
  height: 20px;
  -webkit-appearance: none;
  width: 100%;
}

input[type=range] {

  &::-moz-range-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: #2C9961;
    border-radius: 1px;
    border: 1px solid #000;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: #2C9961;
    border-radius: 1px;
    border: 1px solid #000;
  }

}


input[type=range] {
  &::-webkit-slider-thumb {
    height: 23px;
    width: 23px;
    border-radius: 100%;
    background: #2C9961;
    border: 1px solid black;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  &::-moz-range-thumb{
    height: 23px;
    width: 23px;
    border-radius: 100%;
    background: #2C9961;
    border: 1px solid black;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
}
</style>
