<template>
    <img src="@/assets/icons/speakerphone.svg" height="20px" class="green-filter pointer-event invert" @click="startSpeechToTxt(tts)">
</template>

<script>
export default {
  name: "text-to-speech",
  props: ['tts'],
  data() {
    return {
      runtimeTranscription_: "",
      transcription_: [],
      lang_: "de-DE"
    };
  },
  methods: {
    startSpeechToTxt(tts) {
      let utterance = new SpeechSynthesisUtterance(tts);
      utterance.rate = .7
      window.speechSynthesis.speak(utterance);
    }
  }
}
</script>

<style scoped lang="scss">
.darkmode {
  .invert {
    filter: invert(100%);
  }
}
</style>