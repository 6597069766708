import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/*
    apiPath: 'https://backend.1azugang-lernapp.de/api',
    imgPath: 'https://backend.1azugang-lernapp.de/images/courseOverview/',
    partImages: 'https://backend.1azugang-lernapp.de/images/',
 */


export default new Vuex.Store({
  state: {
    design: {
      mode: 'dark',
      size: 'normal',
    },
    headbar: true,
    showAnswer: false,
    showSettings: false,
    area: 'Berufsbilder',
    apiPath: `${process.env.VUE_APP_BASE_API_URL}api`,
    trackingPath: `${process.env.VUE_APP_BASE_API_URL}tracking`,
    tokenValidator: `${process.env.VUE_APP_BASE_API_URL}token/validator`,
    imgPath: `${process.env.VUE_APP_BASE_API_URL}images/courseOverview/`,
    partImages: `${process.env.VUE_APP_BASE_API_URL}images/`,
    scrollToTop: 0,
    answerQuestion: 0,
    showAllAnswers: false,
    lastPart: 0,
    progressBarUpdate: 0,
    progressBarData: {
      editedQuestions: [],
      wrongAnswered: [],
      rightAnswered: []
    }
  },
  mutations: {
    changeDesign(state, payload) {
      state.design[payload.setting] = payload.value;
      localStorage.setItem('settings', JSON.stringify(state.design));
    },
    setDesign(state, payload) {
      state.design = payload;
    },
    progressBarUpdate(state) {
      state.progressBarUpdate += 1;
    },
    resetProgressBar(state) {
      state.progressBarData = {
        editedQuestions: [],
        wrongAnswered: [],
        rightAnswered: []
      }
    },
    addProgressBarEdited(state, payload) {
      state.progressBarData.editedQuestions.push(payload);
      state.progressBarUpdate += 1;
    },
    setProgressBarWrongAnswered(state, payload) {
      state.progressBarData.wrongAnswered = payload
      state.progressBarUpdate += 1;
    },
    setProgressBarRightAnswered(state, payload) {
      state.progressBarData.rightAnswered = payload
      state.progressBarUpdate += 1;
    },
    removeProgressBarEdited(state, payload) {
      state.progressBarData.editedQuestions.remove(payload);
      state.progressBarUpdate += 1;
    },
    setLastPart(state, payload) {
      state.lastPart = payload;
    },
    showHeadbar(state, payload) {
      state.headbar = payload;
    },
    showAnswer(state, payload) {
      state.showAnswer = payload;
    },
    showSettings(state, payload) {
      state.showSettings = payload;
    },
    changeArea(state, payload) {
      state.area = payload;
    },
    scroller(state, payload) {
      state.scrollToTop += payload
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getDesign: (state) => {
      return state.design;
    },
    getProgressBarDataChange: (state) => {
      return state.progressBarUpdate;
    },
    getprogressBarData: (state) => {
      return state.progressBarData;
    },
    getShowAnswer: (state) => {
      return state.showAnswer;
    },
    getShowSettings: (state) => {
      return state.showSettings;
    },
    getLastPart: (state) => {
      return state.lastPart;
    },
    getArea: (state) => {
      return state.area;
    },
    getHeadbar: (state) => {
      return state.headbar;
    }
  }
})
