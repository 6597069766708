<template>
  <div>
    <div>
      <div class="tour-backpage">

        <div class="container-fluid">
          <div class="row">
            <VueSlickCarousel ref="tour_slider" v-bind="settings" @afterChange="change">
              <Slide_welcome></Slide_welcome>
              <Slide_help></Slide_help>
              <Slide_learn></Slide_learn>
              <Slide_question></Slide_question>
              <Slide_test></Slide_test>
            </VueSlickCarousel>
          </div>
          <div class="bottom-row d-flex justify-content-between">
            <button class="btn p-3 w-50 text-uppercase" @click="leave">Überspringen</button>
            <button class="btn btn-outline-success p-3 w-50 text-uppercase" @click="nextStep" :class="{'btn-success': this.btn_text == 'fertig',}">{{ btn_text }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Slide_welcome from "@/components/tour/slide_welcome";
import Slide_help from "@/components/tour/slide_help";
import Slide_learn from "@/components/tour/slide_learn";
import Slide_question from "@/components/tour/slide_question";
import Slide_test from "@/components/tour/slide_test";


export default {
  name: "tour",
  components: {Slide_test, Slide_question, Slide_learn, Slide_help, Slide_welcome, VueSlickCarousel},
  data() {
    return {
      page: 0,
      btn_text: 'weiter',
      settings: {
        "dots": true,
        "dotsClass": "slick-dots custom-dot-class",
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 200,
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  },
  methods: {
    leave() {
      this.$store.commit('showHeadbar', true)
      this.$router.push('/')
    },
    nextStep() {
      if (this.page < 4) {
        this.$refs.tour_slider.next()
      } else {
        this.$store.commit('showHeadbar', true)
        this.$router.push('/')
      }
    },
    change(int) {
      this.page = int
      if(int > 3) {
        this.btn_text = 'fertig'
      } else {
        this.btn_text = 'weiter'
      }
    }
  },
  mounted() {
    this.$store.commit('showHeadbar', false)
  }
}
</script>

<style>
.tour-backpage {
  overflow: hidden;
  padding: 30px 0;
}
.bottom-row {
  position: fixed;
  bottom: 15px;
  left: 0;
  width: 99%;
}

.filter-green {
  filter: invert(42%) sepia(31%) saturate(1057%) hue-rotate(100deg) brightness(93%) contrast(88%);
}

.btn-success {
  color: white !important;
}
</style>