<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img src="@/assets/icons/android-list.svg" class="mt-6 filter-green" style="width: 100px">
      </div>
      <div class="col-12 text-center">
        <div class="headline"><h4>Lernen</h4></div>
        <div class="middleblock mt-4">
          Wählen Sie zwischen verschiedenen Teilen und lernen Sie Schritt für Schritt.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slide_learn",
}
</script>

<style scoped>
.headline {
  margin-top: 120px;
}
.mt-6 {
  margin-top: 60px;
}
</style>