<template>
  <div>
    <h1>Impressum</h1>
    <p><strong>1a Zugang Beratungsgesellschaft mbH</strong><br>
      Robert-Bosch-Straße 15<br>
      71116 Gärtringen<br>
      Tel.: 07034 27041-300<br>
      Fax: 07034 27041-302<br>
      <a href="http://www.1a-zugang.de">www.1a-zugang.de</a><br>
      <a href="mailto:support@1a-zugang.de">support@1a-zugang.de</a><br>
      UST-ID-Nr. DE287796139<br>
      Handelsregister B Stuttgart 743839</p>

    <p><strong>Geschäftsführerin</strong><br>
      Maren Plehn</p>

    <p><strong>Betriebshaftpflichtversicherung</strong><br>
      bei Allianz Versicherungs-Aktiengesellschaft<br>
      Geltungsbereich des Versicherungsschutzes:<br>
      1a Zugang Beratungsgesellschaft mbH</p>
  </div>
</template>

<script>
export default {
  name: "imprint"
}
</script>

<style scoped>

</style>