<template>
  <div>
    <h1>Datenschutzerklärung für Apps nach der Datenschutz-Grundverordnung
      (DSGVO)</h1>

    <div class="mb-3">
      <h3>
        § 1 Allgemein
      </h3>
      <p>
        Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und behandeln diese
        vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Diese Datenschutzerklärung gilt für unsere mobilen iPhone- und
        AndroidApps (im Folgenden „APP“). In ihr werden Art, Zweck und Umfang der
        Datenerhebung im Rahmen der APP-Nutzung erläutert. Wir weisen darauf hin, dass die
        Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
        Daten vor dem Zugriff durch Dritte ist nicht möglich.
      </p>
    </div>

    <div class="mb-3">
      <h3>
        (1) Verantwortliche Stelle
      </h3>
      <p>
        Verantwortliche Stelle für die Datenverarbeitung im Rahmen dieser APP ist:
        1a Zugang Beratungsgesellschaft mbH
        Robert-Bosch-Straße 15
        71116 Gärtringen
        Tel.: 07034 27041-300
        Fax: 07034 27041-302
        zugang@1a-zugang.de
        <a href="www.1a-zugang.de">
          www.1a-zugang.de
        </a>
      </p>
    </div>

    <div class="mb-3">
      <h3>
        (2) Datenschutzbeauftragter
      </h3>
      <p>
        Unseren Datenschutzbeauftragten erreichen Sie unter:<br><br>
        Herr Frank Binder
        Binder IT-Systemhaus GmbH
        Max-Eyth-Straße 38
        71088 Holzgerlingen
        <a href="mailto:Datenschutz@1a-zugang.de">Datenschutz@1a-zugang.de</a>
      </p>
    </div>

    <div class="mb-3">
      <h3>
        (3) Allgemeine Speicherdauer personenbezogener Daten
      </h3>
      <p>
        Vorbehaltlich abweichender oder konkretisierender Angaben innerhalb dieser
        Datenschutzerklärung werden die von dieser APP erhobenen personenbezogenen Daten
        gespeichert, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt. Sofern eine gesetzliche Pflicht
        zur Aufbewahrung oder ein sonstiger gesetzlich anerkannter Grund zur Speicherung der
        Daten (z. B. berechtigtes Interesse) besteht, werden die betreffenden personenbezogenen
        Daten nicht vor Wegfall des jeweiligen Aufbewahrungsgrundes gelöscht.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        (4) Rechtsgrundlage zur Speicherung personenbezogener Daten
      </h3>
      <p>
        Die Verarbeitung personenbezogener Daten ist nur zulässig, wenn eine wirksame
        Rechtsgrundlage für die Verarbeitung dieser Daten besteht. Sofern wir Ihre Daten verarbeiten,
        geschieht dies regelmäßig auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und
        § 25 Abs. 1 TTDSG, zum Zwecke der Vertragserfüllung nach Art. 6 Abs. 1 lit. b DSGVO (z. B.
        bei Nutzung von In-APP-Käufen oder der Nutzung sonstiger kostenpflichtiger APP-
        Funktionen) oder aufgrund berechtigter Interessen nach Art. 6 Abs. 1 lit. f DSGVO, die stets
        mit Ihren Interessen abgewogen werden (z. B. im Rahmen von Werbemaßnahmen). Die
        jeweils einschlägigen Rechtsgrundlagen werden ggf. an gesonderter Stelle im Rahmen
        dieser Datenschutzerklärung konkretisiert.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        (5) Verschlüsselung
      </h3>
      <p>
        Diese APP nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
        Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als APP- Betreiber senden, oder der
        Kommunikation der APP-Nutzer untereinander, eine Verschlüsselung. Durch diese
        Verschlüsselung wird verhindert, dass die Daten, die Sie übermitteln, von unbefugten Dritten
        mitgelesen werden können.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        (6) Änderung dieser Datenschutzerklärung
      </h3>
      <p>
        Wir behalten uns das Recht vor, diese Datenschutzbestimmungen unter Einhaltung der
        gesetzlichen Vorgaben jederzeit zu ändern.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        § 2 Ihre Rechte
      </h3>
      <p>
        Die DSGVO gewährt Betroffenen, deren personenbezogene Daten von uns verarbeitet
        werden, bestimmte Rechte, über die wir Sie an dieser Stelle aufklären möchten:
      </p>
    </div>
    <div class="mb-3">
      <h3>
        (1) Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </h3>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer Einwilligung möglich. Diese werden wir
        vor Beginn der Datenverarbeitung ausdrücklich bei Ihnen einholen. Sie können diese
        Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
        Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
        Widerruf unberührt.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        WIDERSPRUCHSRECHT GEGEN DIE DATENERHEBUNG IN BESONDEREN FÄLLEN SOWIE
        GEGEN DIREKTWERBUNG (ART. 21 DSGVO)
      </h3>
      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
        ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER
        BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG SIE BETREFFENDER
        PERSONENBEZOGENER DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF
        DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGEN, AUF
        DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
        DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR DIE
        BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN,
        WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
        DER VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
        RECHTSANSPRÜCHEN DIENEN.
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT
        SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN
        IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
        DIREKTWERBUNG VERWENDET.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        (2) Beschwerderecht bei einer Aufsichtsbehörde
      </h3>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei
        einer Aufsichtsbehörde zu. Das Beschwerderecht besteht unbeschadet anderweitiger
        verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        (3) Auskunft, Löschung und Beseitigung
      </h3>
      <p>
        Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung sowie ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu
        sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
        unter der im Impressum angegebenen Adresse an uns wenden.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        (4) Recht auf Einschränkung der Verarbeitung
      </h3>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse
        an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
        Fällen:
      </p>
      <ul>
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
          benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
          das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah /
          geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung
          verlangen.
        </li>
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
          Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben
          Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
          Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch
          nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </li>
      </ul>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese
        Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses
        der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
      </p>
    </div>

    <div class="mb-3">
      <h3>
        (5) Recht auf Datenübertragbarkeit
      </h3>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
        Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
        maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der
        Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
    </div>

    <div class="mb-2">
      <h3>§ 3 Erfassung personenbezogener Daten im Rahmen der APP-Nutzung</h3>
    </div>

    <div class="mb-3">
      <h3>
        (1) Allgemein
      </h3>
      <p>
        Wenn Sie unsere APP nutzen, erfassen wir folgende personenbezogene Daten von Ihnen:
      </p>
      <ul>
        <li>Vor- und Nachname</li>
        <li>Ggf. E-Mail-Adresse</li>
        <li>Nutzungsdaten</li>
        <li>IP-Adresse</li>
        <li>Gerätekennung</li>
        <li>Metadaten</li>
      </ul>
      <p>
        Die Verarbeitung dieser personenbezogenen Daten ist erforderlich, um die Funktionalitäten der
        APP zu gewährleisten. Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO, Ihre Einwilligung im Sinne des Art. 6 Abs. 1 lit. a
        DSGVO und § 25 Abs. 1 TTDSG und – sofern ein Vertrag geschlossen wurde – die Erfüllung
        unserer vertraglichen Verpflichtungen (Art. 6 Abs. 1 lit. b DSGVO).
        <br>
        <br>
        Wir speichern Ihre personenbezogenen Daten solange, wie es für die Erfüllung des
        Vertragsverhältnisses erforderlich ist. Darüber hinaus werden die Daten 12 Monate nach
        Beendigung des Vertragsverhältnisses endgültig und unwiederbringlich gelöscht.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        (2) Anfrage innerhalb der APP, per E-Mail, Telefon oder Telefax
      </h3>
      <p>
        Wenn Sie uns kontaktieren (z. B. via Kontaktformular innerhalb der App, per E-Mail, Telefon oder
        Telefax), wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (z. B. Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
        verarbeitet. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO,
        sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
        vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren berechtigten Interessen (Art.
        6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen haben. Die von Ihnen an uns per Kontaktanfrage übersandten Daten
        verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener
        Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
        gesetzliche Aufbewahrungsfristen – bleiben unberührt. Wir geben Ihre Daten nicht ohne Ihre
        Einwilligung weiter.
        <br>
        <strong>Diese Einwilligung können Sie jederzeit widerrufen.</strong>Die von Ihnen zum Zwecke des Newsletter-
        Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter
        gespeichert und nach der Austragung gelöscht.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        § 4 Datenanalyse
      </h3>
      <p>
        Wenn Sie auf unsere APP zugreifen, kann Ihr Verhalten mit Hilfe bestimmter Analyse- Werkzeuge
        statistisch ausgewertet und zu Werbe- und Markforschungszwecken oder zur Verbesserung unserer
        Angebote analysiert werden. Bei der Verwendung derartiger Tools achten wir auf die Einhaltung
        der gesetzlichen Datenschutzbestimmungen. Beim Einsatz externer Dienstleister
        (Auftragsverarbeiter) stellen wir durch entsprechende Verträge mit den Dienstleistern sicher, dass
        die Datenverarbeitung den deutschen und europäischen Datenschutzstandards entspricht.
      </p>
    </div>
    <div class="mb-3">
      <h3>
        Stand: 12. 2023
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "policy"
}
</script>

<style scoped>

</style>