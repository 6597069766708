<template>
  <div class="questionSet position-relative">
    <span class="headline pt-3" v-if="this.$route.params.part !== 'test'">
      {{ getPartname }}
    </span>
    <span class="headline pt-3" v-else>
      Test
    </span>
    <span class="headline pb-1">
      Frage {{ index + 1 }}
    </span>
    <h2 class="mb-4">{{ questions.question }}</h2>
    <div class="row mb-4">
      <div class="col-10 col-md-11">
          <template v-if="questions.img_scr && showPicture && questions.img_scr !== 'Src'">
            <img class="d-block"
                 :src="getImageSource(questions.imgScr)"
            >
          </template>

        <div class="correct-answers">{{ questions.correctAnswerNumber }} Antwort<span v-if="questions.correctAnswerNumber > 1">en sind </span> <span v-else> ist</span> richtig</div>
      </div>
      <div class="col-2 col-md-1">
        <text-to-speech :tts="questions.question" />
      </div>
    </div>
    <div class="row"  v-for="(question, index) in questionSet" :key="index">
      <div class="col-10 col-md-11">
        <div class="col-12 check-answer d-flex label-wrapper">
          <span v-if="showAnswer || showAllAnswers" class="mr-2">
            <img src="@/assets/icons/android-done.svg" class="answer green-filter" v-if="rightOrWrong(index)">
            <img src="@/assets/icons/android-close.svg" class="answer red-filter" v-else>
          </span>

            <input type="checkbox" :id="question.answer" :disabled="showAnswer || showAllAnswers" :name="index" v-model="questionValues[index]" class="input-box pointer" @click="safeAnswer(index, !questionValues[index])">
            <label :for="question.answer" class="pointer w-100">
              {{ question.answer }}
              <template v-if="question.img_src && showPicture && question.img_src !== 'Src'">
                <img class="d-block"
                    :src="getImageSource(question.img_src)"
                >
              </template>
            </label>

        </div>
      </div>
      <div class="col-2 col-md-1 check-answer">
        <text-to-speech :tts="question.answer" />
      </div>
      <div class="col-12">
        <hr>
      </div>

      <!-- Modal question check -->
    </div>
    <b-modal :id="'modal' + index" hide-footer hide-header size="md" centered>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p v-if="wrongAnswered">
                <img src="@/assets/icons/ios-close-outline.svg" class="w-100 red-filter">
              </p>
              <p v-else>
                <img src="@/assets/icons/ios-checkmark-outline.svg" class="w-100 green-filter">
              </p>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import TextToSpeech from "../tts/text-to-speech";
import axios from "axios";

export default {
  name: "questionSet",
  components: {TextToSpeech},
  props: ['questions', 'index', 'load', 'headline'],
  data() {
    return {
      questionValues: [],
      questionSet: {},
      question: this.index,
      wrongAnswered: false,
      actualPart: '',
      error: [],
      showPicture: false
    }
  },
  watch: {
    showAnswer() {
      if (this.question + 1 == this.answerQuestion) {
        if (this.showAnswer) {
          this.$bvModal.show('modal'+ this.index);
        }
      }
    },
  },
  computed: {
    getPartname() {
      return localStorage.getItem('actualPartName')
    },
    showAnswer() {
      return this.$store.state.showAnswer
    },
    answerQuestion() {
      return this.$store.state.answerQuestion;
    },
    showAllAnswers() {
      return this.$store.state.showAllAnswers;
    }
  },
  methods: {
    getImageSource(img_src) {
      if (img_src.includes('upload_')) {
        return this.$store.state.partImages  + 'uploads/' + img_src
      }

      return this.$store.state.partImages + this.$route.params.id + '/' + this.actualPart + '/800px/' + img_src
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    },
    safeAnswer(index, value) {
      if (!this.showAnswer) {
        let temp = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_' + 'questions_' + this.index))
        temp.questions[index] = value;
        temp.edited = true;
        this.$store.commit('addProgressBarEdited', this.index)
        localStorage.setItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_' + 'questions_' + this.index, JSON.stringify(temp));
      }
    },
    getRightOrWrong(index) {
      let temp = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_' + 'questions_' + (this.index))).questions
      if (this.questionSet[index].isCorrect === temp[index]) {
        return true
      } else {
        return false
      }
    },
    rightOrWrong(index) {
      if (this.index + 1 == this.answerQuestion  || this.showAllAnswers) {
        let temp = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_' + 'questions_' + (this.index))).questions

        if (this.questionSet[index].isCorrect) {
          if (this.questionSet[index].isCorrect && !temp[index]) {
            this.wrongAnswered = true
          }
          return true
        } else {
          if (!this.questionSet[index].isCorrect && temp[index]) {
            this.wrongAnswered = true
          }
          return false
        }
      }
    },
  },
  mounted() {
    let path = this.$route.params.id
    let part =  this.questions.id

    if(this.$route.params.testMode) {
      path = this.$route.params.id
      part =  this.questions.id

      axios.get(this.$store.state.apiPath + '/parts/' + this.questions.part_id.split("/")[3], {headers: {
          'accept': 'application/ld+json'
        }} )
          .then(response => {
            if(response.data) {
              this.actualPart = response.data.front_id
              this.showPicture = true
            }
          })
          .catch(e => {
            this.error.push(e)
            this.textfieldError = true;
          });
    } else {
      path = this.$route.params.id
      part = this.$route.params.part
      axios.get(this.$store.state.apiPath + '/parts/' + this.questions.part_id.split("/")[3], {headers: {
          'accept': 'application/ld+json'
        }} )
          .then(response => {
            if(response.data) {
              this.actualPart = response.data.front_id
              this.showPicture = true
            }
          })
          .catch(e => {
            this.error.push(e)
            this.textfieldError = true;
          });
    }


    // get part for images
    axios.get(this.$store.state.apiPath + '/answers/', { params: { question_id: this.questions.id }, headers: { 'accept': 'application/json'}})
        .then(response => {
          if(response.data) {
            if (this.$route.params.testMode) {
              part = 'test';
            }
            this.shuffleArray(response.data)
            localStorage.setItem('lecture_' + path + '_' + part + '_question' + '_questionID_' + (this.index), JSON.stringify(response.data))
            this.questionSet = response.data
            this.showPicture = true
            this.isReady = true
          }

          if (this.load) {
            let questionAnswers = []
            for (let i = 0; i < this.questionSet.length; i++) {
              questionAnswers[i] = false
            }
            let temp = {
              edited: false,
              editable: true,
              result: null,
              questions: questionAnswers
            }
            localStorage.setItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_' + 'questions_' + (this.index), JSON.stringify(temp))
          } else {
            let edited = false;
            for (let i = 0 ; i < this.questionSet.length ; i++) {
              this.questionValues[i] = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_' + 'questions_' + (this.questions.id - 1 ))).questions[i]
              if (this.questionValues[i]) {
                edited = true
              }
            }

            if (edited) {
              this.$store.commit('addProgressBarEdited', this.index)
            }
          }
        })
        .catch(e => {
          this.error.push(e)
        });
  }
}
</script>

<style lang="scss" scoped>
.questionSet {
  margin-bottom: 5rem;
  min-height: 100%;

  img {
    border: 1px solid #000;
    width: 100%;
    max-width: 800px;
  }

  .label-wrapper {
    min-height: 40px;

    label {
      word-wrap: break-word;
    }
  }

  .col-md-1 {
    img {
      margin-left: 0;
      border: none;
      height: 30px;
      width: 30px;
      padding: 2px;
      cursor: pointer;
    }
  }

  .check-answer {
     margin: 10px 0;
     font-size: 1.2rem;

    .answer {
      margin: 0;
      padding-bottom: 10px;
      width: 30px;
      border: none;
    }
  }
}

.green-filter {
  filter: invert(45%) sepia(93%) saturate(334%) hue-rotate(97deg) brightness(91%) contrast(86%);
}

.red-filter {
  filter: invert(47%) sepia(67%) saturate(3175%) hue-rotate(331deg) brightness(107%) contrast(92%);
}

.headline {
  display: block;
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 600;
  color: #9b9b9b;
  line-height: 1.2;
}

.mr-2 {
  margin-right: 10px;
}

.darkmode {
  .questionSet {

  }
}
</style>
