<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img src="@/assets/icons/ios-body.svg" class="mt-6 filter-green" style="width: 100px">
      </div>
      <div class="col-12 text-center">
        <div class="headline"><h4>Bedienungshilfen</h4></div>
        <div class="middleblock mt-4">
          Einstellungen und Bedienungshilfen für die Leicht Lernen App
          <div class="mt-3">
            <button v-b-modal.settings class="btn btn-success text-uppercase px-3 py-2">Bedienungshilfen öffnen</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal id="settings" title="Bedienungshilfen" centered hide-header-close hide-footer>
      Darstellungsgröße
      <hr>
      <form>
        <div class="d-flex flex-row-reverse justify-content-between mx-2 py-2 my-2">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="darstellung_normal" @click="safeSetting('size', 'normal')" :checked="size == 'normal'">
          <label class="form-check-label w-100" for="darstellung_normal">
            <span class="bubble p-1" style="font-size: 12px">A</span>normal
          </label>
        </div>
        <div class="d-flex flex-row-reverse justify-content-between mx-2 py-2 my-2">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="darstellung_groß" @click="safeSetting('size', 'big')" :checked="size == 'big'">
          <label class="form-check-label w-100" for="darstellung_groß">
            <span class="bubble" style="font-size: 18px">A</span>groß
          </label>
        </div>
      </form>
      Aussehen
      <hr>
      <form>
        <div class="d-flex flex-row-reverse justify-content-between mx-2 py-2 my-2">
          <input class="form-check-input" type="radio" name="mode" value="light" id="aussehen_hell" @click="safeSetting('mode', 'light')" :checked="mode == 'light'">
          <label class="form-check-label w-100" for="aussehen_hell">
            <span class="bubble whiteground p-1" style="font-size: 12px"></span>hell
          </label>
        </div>
        <div class="d-flex flex-row-reverse justify-content-between mx-2 py-2 my-2">
          <input class="form-check-input" type="radio" name="mode" value="light" id="aussehen_dunkel" @click="safeSetting('mode', 'dark')" :checked="mode == 'dark'">
          <label class="form-check-label w-100" for="aussehen_dunkel">
            <span class="bubble blackground p-1" style="font-size: 12px"></span>dunkel
          </label>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "slide_help",
}
</script>

<style scoped>
.headline {
  margin-top: 120px;
}
.mt-6 {
  margin-top: 60px;
}


.ptr {
  cursor: pointer;
}

.btn-green {
  background-color: #008641;
  color: white;
  font-size: 16px;
  font-weight: lighter;
}

.form-check-input:checked {
  background-color: #008641;
  border-color: #008641;
}

.lighter-headline {
  color: #ABABAB;
  font-size: 18px;
}

.form-check-label {
  display: flex;
  align-content: center;

}

.bubble {
  border: 1px solid black;
  border-radius: 100%;
  display: inline-flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-content: center;
  margin-right: 6px;
}

.blackground {
  background-color: black;
}

.back {
  padding-left: 30px;
  color: #B6B6B6;
  font-size: 20px;
}

.space-left {
  padding-left: 10px;
}

.btn:hover {
  color: white;
  background-color: #008641;
}

.btn:focus {
  color: white;
  background-color: #008641;
}

.modal-body {
  float: right;
  height: 70vh;
  padding: 3px;
}

.scrollable-content {
  height: 100%;
  margin: 0em;
  overflow: auto;
}

li {
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

</style>