<template>
  <div class="settings" :class="{'darkmode': darkmode}">

    <!-- Modal -->
    <b-modal id="modal-1" scrollable title="Datenschutzrichtlinien">
      <policy></policy>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <!-- Modal -->
    <b-modal id="modal-2" scrollable title="Impressum">
      <imprint>
      </imprint>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <!-- view -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 my-3">
          <div class="space-left">
            <span class="fw-bold fs-5 ptr" @click="closeSettings">&#8592;</span>
            <span class="back">
              Einstellungen
            </span>
          </div>
        </div>
        <hr>
        <div class="col-12">
          <button @click="home" class="btn btn-green w-100 text-uppercase py-2 my-2"> Alle Berufsbilder
          </button>
        </div>
        <div class="col-12">
          <button @click="tour" class="btn btn-green w-100 text-uppercase py-2 my-2"> Einführungs Tour
          </button>
        </div>
        <div class="col-12">
          <b-button v-b-modal.modal-1 class="btn btn-green w-100 text-uppercase py-2 my-2">Datenschutzrichtlinien anzeigen</b-button>
        </div>
        <div class="col-12">
          <b-button v-b-modal.modal-2 class="btn btn-green w-100 text-uppercase py-2 my-2">Impressum anzeigen</b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="setting-section mt-4">
            <span class="lighter-headline">
            Darstellungsgröße
            </span>
          </div>
          <hr>
          <form>
            <div class="d-flex flex-row-reverse justify-content-between mx-2 py-2 my-2">
              <input class="form-check-input pointer" type="radio" name="flexRadioDefault" id="darstellung_normal" @click="safeSetting('size', 'normal')" :checked="size == 'normal'">
              <label class="form-check-label w-100 pointer" for="darstellung_normal">
                <span class="bubble p-1" style="font-size: 12px">A</span>normal
              </label>
            </div>
            <div class="d-flex flex-row-reverse justify-content-between mx-2 py-2 my-2">
              <input class="form-check-input pointer" type="radio" name="flexRadioDefault" id="darstellung_groß" @click="safeSetting('size', 'big')" :checked="size == 'big'">
              <label class="form-check-label w-100 pointer" for="darstellung_groß">
                <span class="bubble" style="font-size: 18px">A</span>groß
              </label>
            </div>
          </form>
        </div>
        <div class="col-12">
          <div class="setting-section mt-4">
            <span class="lighter-headline">
            Aussehen
            </span>
          </div>
          <hr>
          <form>
            <div class="d-flex flex-row-reverse justify-content-between mx-2 py-2 my-2">
              <input class="form-check-input pointer" type="radio" name="mode" value="light" id="aussehen_hell" @click="safeSetting('mode', 'light')" :checked="mode == 'light'">
              <label class="form-check-label w-100 pointer" for="aussehen_hell">
                <span class="bubble whiteground p-1" style="font-size: 12px"></span>hell
              </label>
            </div>
            <div class="d-flex flex-row-reverse justify-content-between mx-2 py-2 my-2">
              <input class="form-check-input pointer" type="radio" name="mode" value="light" id="aussehen_dunkel" @click="safeSetting('mode', 'dark')" :checked="mode == 'dark'">
              <label class="form-check-label w-100 pointer" for="aussehen_dunkel">
                <span class="bubble blackground p-1" style="font-size: 12px"></span>dunkel
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- tour -->

  </div>
</template>

<script>
import Policy from "@/components/settings/policy";
import Imprint from "@/components/settings/imprint";

export default {
  name: "settings",
  components: {Policy, Imprint},
  data() {
    return {
      mode: 'light',
      size: 'normal',
      showTour: false
    }
  },
  mounted() {
    this.loadSetting()
  },
  methods: {
    home() {
      this.closeSettings()
      this.$router.push('/berufsbilder');
    },
    closeSettings() {
      this.$store.commit('showSettings', false)
    },
    loadSetting() {
      let settings = JSON.parse(localStorage.getItem('settings'));
      if (settings.size == 'normal') {
        this.size = 'normal'
      } else {
        this.size = 'big'
      }
      if (settings.mode == 'light') {
        this.mode = 'light'
        //document.getElementById("aussehen_hell").setAttribute("checked", "true")
      } else {
        this.mode = 'dark'
      }
    },
    safeSetting(setting, value) {
      let payload = {
        setting: setting,
        value: value
      }
      this.$store.commit('changeDesign', payload);
      let settings = this.$store.getters.getDesign;
      localStorage.setItem('settings', JSON.stringify(settings))
      this.loadSetting()
    },
    tour() {
      this.closeSettings()
      this.$router.push({name: 'tour'});
    }
  },
  computed: {
    darkmode() {
      let design = this.$store.getters.getDesign;
      if (design.mode == 'dark') {
        return true
      }
      return false
    },
    fontSize() {
      let design = this.$store.getters.getDesign;
      if (design.size == 'big') {
        return true
      }
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
.settings {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.ptr {
  cursor: pointer;
}

.btn-green {
  background-color: #008641;
  color: white;
  font-size: 16px;
  font-weight: lighter;
}

.form-check-input:checked {
  background-color: #008641;
  border-color: #008641;
}

.lighter-headline {
  color: #ABABAB;
  font-size: 18px;
}

.form-check-label {
  display: flex;
  align-content: center;

}

.bubble {
  border: 1px solid black;
  border-radius: 100%;
  display: inline-flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-content: center;
  margin-right: 6px;
}

.blackground {
  background-color: black;
}

.back {
  padding-left: 30px;
  color: #B6B6B6;
  font-size: 20px;
}

.space-left {
  padding-left: 10px;
}

.btn:hover {
  color: white;
  background-color: #008641;
}

.btn:focus {
  color: white;
  background-color: #008641;
}

.modal-body {
  float: right;
  height: 70vh;
  padding: 3px;
}

.scrollable-content {
  height: 100%;
  margin: 0em;
  overflow: auto;
}

li {
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}


.darkmode {
  background-color: black;
  color: white;

  .btn-green {
    background-color: white;
    color: black;
  }

  .form-check-input {
    background-color: black;
    border-color: white;
  }

  .form-check-input:checked {
    border-color: white;
    background-color: black;
  }

  .lighter-headline {
    color: #ABABAB;
    font-size: 18px;
  }

  .whiteground {
    background-color: white;
  }

  .bubble {
    border: 1px solid white;
  }

  a {
    color: white;
  }

  a:hover {
    color: black;
  }
}
</style>