<template>
  <div class="questions">
    <div class="progressbar-contanier container-fluid">
      <div class="row progressbar py-3" id="topOF">
        <div class="col-10 col-md-11">
          <progress-bar class="mt-2" :questions="questions.length" :current-view="showQuestionSet" @goTo="goToQuestion"></progress-bar>
        </div>
        <div class="col-2 col-md-1">
          <button @click="closePart" class="btn btn-c w-100">&#x2715;</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 questionSet">
        <template v-if="isReady">
          <VueSlickCarousel ref="questions_carousel" v-bind="settings">
            <div v-for="(questionSet, index) in questions" :key="questionSet.id" >
              <div v-show="(index + 1) === showQuestionSet">
                <question-set :questions="questionSet" :index="index" :load="startNew"></question-set>
              </div>
            </div>
          </VueSlickCarousel>
        </template>
      </div>
      <div class="col-12">
        <div class="buttons">
          <button class="btn btn-arrow" @click="showFirst">
            <img src="@/assets/icons/android-arrow-back.svg" height="30px">
            <img src="@/assets/icons/android-arrow-back.svg" height="30px">
          </button>
          <button class="btn btn-arrow" @click="showPrev">
            <img src="@/assets/icons/android-arrow-back.svg" height="30px">
          </button>

          <button class="btn btn-arrow w-65" :disabled="disableGetAnswers" @click="showAnswers" v-if="!auswertung && !liveTest">lösung anzeigen</button>
          <button class="btn btn-arrow btn-red w-65" @click="wrongAnswersPopOut" v-if="auswertung" style="color: white">Falsche Antworten</button>

          <button class="btn btn-arrow" :class="{'btn-green': !checkPosition && !auswertung, 'btn-red': !checkPosition && auswertung}" :disabled="auswertung && !checkPosition" @click="showNext">
            <img src="@/assets/icons/android-arrow-forward.svg" height="30px" v-if="checkPosition">
            <img src="@/assets/icons/stats-bars.svg" class="filter-white" height="30px" v-if="!checkPosition && !auswertung">
          </button>
          <button class="btn btn-arrow" @click="showLast">
            <img src="@/assets/icons/android-arrow-forward.svg" height="30px">
            <img src="@/assets/icons/android-arrow-forward.svg" height="30px">
          </button>
        </div>
      </div>
    </div>

    <!--Modals-->

    <b-modal id="endpoint" title="Hinweis" hide-footer size="sm" hide-header-close centered>
      <p class="mt-1 mb-4">
        Sie haben noch {{ unAnsweredValue }} unbeantwortete Fragen. Wollen Sie trotzdem mit der Auswertung beginnen ?
      </p>
      <div class="d-flex justify-content-between buttons-modal">
        <button class="btn btn-modal btn-outline-dark text-uppercase py-2 w-50" style="font-size: 12px; margin-right: 10px" @click="$bvModal.hide('endpoint')">Abbrechen</button>
        <button class="btn btn-modal btn-success text-uppercase py-2 w-50" style="font-size: 12px" @click="auswerten">Auswerten</button>
      </div>
      <div>
        <button class="btn btn-modal btn-outline-dark text-uppercase py-2 mt-2 w-100" style="font-size: 12px" @click="$bvModal.show('notAnsweredQuestions')">Unbeantwortete Fragen anzeigen</button>
      </div>
    </b-modal>

    <b-modal id="auswertung" hide-footer size="md" centered>
      <template #modal-header>
        <div class="container-fluid">
          <div class="row auswertung-inner">
            <div class="col-12 text-end">
              <button @click="$bvModal.hide('auswertung')" class="btn btn-c" style="font-size: 30px">&#x2715;</button>
            </div>
            <div class="col-12">
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" aria-valuenow="" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + progressBarValue + '%'"></div>
              </div>
            </div>
            <div class="col-12">
              <h5 class="mt-4 mb-4">
                Sie haben
                <span style="font-size: 28px; color: #008641">{{ reachedPoints }}</span>
                von {{ maxPoints }} richtige Antworten gegeben.
              </h5>
            </div>
            <hr>
            <div class="col-12">
              <h5>
                Sie haben
                <span style="color: #A02330">{{ wrongQuestions }}</span>
                der Fragen falsch beantwortet.
              </h5>
            </div>
          </div>
        </div>
      </template>
      <div class="text-center buttons-modal">
        <button class="btn btn-modal btn-success text-uppercase py-3 w-50" style="font-size: 16px" @click="proofFailures">Fehler Überprüfen</button>
      </div>
    </b-modal>

    <b-modal id="closePart" :title="'Wollen Sie ' + (liveTest ? 'den Test' : 'die Übung') + ' wirklich verlassen?'" hide-footer size="sm" hide-header-close centered>
      <p class="mt-1 mb-4">
        Wenn Sie {{ liveTest ? 'den Test' : 'die Übung' }} verlassen, können Sie zu einem späteren Zeitpunkt mit dem aktuellen Stand fortfahren.
      </p>
      <div class="d-flex justify-content-between buttons-modal">
        <button class="btn btn-modal btn-outline-dark text-uppercase py-2 w-50" style="font-size: 12px; margin-right: 10px" @click="$bvModal.hide('closePart')">Abbrechen</button>
        <button class="btn btn-modal btn-success text-uppercase py-2 w-50" style="font-size: 12px" @click="closeTests()">{{liveTest ? 'Test' : 'Übung'}} verlassen</button>
      </div>
    </b-modal>

    <b-modal id="choseQuestion" size="md" centered>
      <template #modal-header>
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              Aufgaben, die Fehler enthalten
            </div>
          </div>
        </div>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-2 col-3" v-for="question in wrongAnswered" :key="question+1000">
            <button class="btn btn-bubble mb-2" :class="{'btn-active-question': question + 1 == showQuestionSet}" @click="goToQuestion(question)">{{ question + 1 }}</button>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <button @click="$bvModal.hide('choseQuestion');" class="btn btn-close-modal text-uppercase">schliessen</button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal id="notAnsweredQuestions" size="md" centered>
      <template #modal-header>
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              Unbearbeitete Aufgaben
            </div>
          </div>
        </div>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-2 col-3" v-for="question in notAnswered" :key="question+1000">
            <button class="btn btn-bubble mb-2" :class="{'btn-active-question': question + 1 == showQuestionSet}" @click="goToQuestion(question); $bvModal.hide('notAnsweredQuestions'); $bvModal.hide('endpoint')">{{ question + 1 }}</button>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <button @click="$bvModal.hide('notAnsweredQuestions');" class="btn btn-close-modal text-uppercase">schliessen</button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";
import QuestionSet from "@/components/questions/questionSet";
import VueSlickCarousel from "vue-slick-carousel";
import ProgressBar from "@/components/questions/progress-bar";
import qs from "query-string";

export default {
  name: "questions",
  components: {ProgressBar, QuestionSet, VueSlickCarousel},
  data() {
    return {
      unAnsweredValue: 0,
      questions: {},
      startNew: false,
      answerQuestion: '',
      wrongQuestions: 0,
      progressBarValue: 0,
      wrongAnswered: [],
      notAnswered: [],
      btnStatistic: false,
      disableGetAnswers: false,
      showQuestionSet: 1,
      rightAnswers: false,
      isReady: false,
      reachedPoints: 0,
      auswertung: false,
      maxPoints: 0,
      settings: {
        "dots": false,
        "arrows": false,
        "focusOnSelect": false,
        "infinite": false,
        "speed": 100,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "centerMode": false,
        "swipe": false,
        "draggable": false,
        "swipeToSlide": false,
      },
    }
  },
  methods: {
    proofFailures() {
      this.$bvModal.hide('auswertung');
      this.wrongAnswersPopOut();
    },
    closeTests() {
      this.$store.commit('showAnswer', false)
      this.$store.state.showAllAnswers = false;
      this.$router.go(-1);
    },
    wrongAnswersPopOut() {
      this.$bvModal.show('choseQuestion');
    },
    goToQuestion(index) {
      this.disableGetAnswers = false;
      this.$store.commit('showAnswer', false);
      this.showQuestionSet = index+1;
      this.$refs.questions_carousel.goTo(index);
      this.$bvModal.hide('choseQuestion');
    },
    auswerten() {
      this.$store.commit('setProgressBarWrongAnswered', this.wrongAnswered);

      let temp = []
      for (let i = 0 ;i < this.questions.length ; i++) {
        if (!this.wrongAnswered.includes(i)) {
          temp.push(i)
        }
      }

      this.$store.commit('setProgressBarRightAnswered', temp);
      this.$store.state.showAllAnswers = true;
      this.$bvModal.hide('endpoint') ;
      this.auswertung = true;
      this.$bvModal.show('auswertung');
    },
    closePart() {
      this.disableGetAnswers = false;
      this.$bvModal.show('closePart');
    },
    showAnswers() {
      this.$store.state.answerQuestion = this.showQuestionSet
      this.disableGetAnswers = true;
      this.$store.commit('showAnswer', true);
    },
    getQuestions() {
      let path = this.$route.params.id
      let part = this.$route.params.part

      localStorage.setItem('lecture_' + path + '_' + part + '_started', true)

      axios.get(this.$store.state.apiPath + '/questions/', { params: { part_id: part }})
          .then(response => {
            if(response.data) {
              localStorage.setItem('lecture_' + path + '_' + part, JSON.stringify(response.data["hydra:member"]))
              this.questions = response.data["hydra:member"]
              this.isReady = true
            }
          })
          .catch(e => {
            this.error.push(e)
            this.textfieldError = true;
          });
    },
    showPrev() {
      window.speechSynthesis.cancel();
      document.getElementById("topOF").scrollIntoView({behavior: "smooth"})
      if (this.showQuestionSet != 1) {
        this.disableGetAnswers = false;
        if (!this.auswertung) {
          this.$store.commit('showAnswer', false)
        }
        this.showQuestionSet -= 1
        this.$refs.questions_carousel.prev();
      }
    },
    showFirst() {
      this.goToQuestion(0);
    },
    showLast() {
      this.goToQuestion(this.questions.length - 1);
    },
    showNext() {
      window.speechSynthesis.cancel();
      document.getElementById("topOF").scrollIntoView({behavior: "smooth"})
      if (this.showQuestionSet != this.questions.length && !this.liveTest) {
        this.$store.commit('showAnswer', false);
        this.$store.commit('scroller', 1);
        this.disableGetAnswers = false;
        this.showQuestionSet += 1;
        this.$refs.questions_carousel.next();
      } else if(this.showQuestionSet == this.questions.length && !this.liveTest) {
        this.evaluation();
      } else if (this.showQuestionSet != this.questions.length && this.liveTest) {
        this.showQuestionSet += 1;
        this.$refs.questions_carousel.next()  ;
      } else if (this.showQuestionSet == this.questions.length && this.liveTest) {
        this.evaluation();
      }
    },
    evaluation() {
      this.unAnswered()
      this.gettedPoints()
      if (this.unAnsweredValue != 0) {
        this.$bvModal.show('endpoint');
      } else {
        this.auswertung = true;
        this.$store.state.showAllAnswers = true;
        this.$bvModal.show('auswertung');
      }

    },
    unAnswered() {
      this.notAnswered = [];
      let len = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part)).length;

      let counter = 0;
      for (let i = 0; i < len; i++) {
        if (!JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_questions_' + i)).edited) {
          this.notAnswered.push(i)
          counter++;
        }
      }
      this.unAnsweredValue = counter;
    },
    gettedPoints() {
      this.reachedPoints = 0;
      this.maxPoints = 0 ;
      this.wrongQuestions = 0;
      this.progressBarValue = 0;
      this.wrongAnswered = [];

      let len = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part)).length;
      for (let i = 0; i < len; i++) {
        let questionslen = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_question_questionID_' + i)).length;
        let wrongAnsweredCounter = 0;

        for (let j = 0 ; j < questionslen; j++) {
          let question = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_question_questionID_' + i))[j].isCorrect;
          let answer = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_questions_' + i)).questions[j]
          let edited = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_questions_' + i)).edited

          if (edited && question == answer && question == true) {
            this.reachedPoints++
          }

          if (question != answer) {
            wrongAnsweredCounter++
          }

          if (question) {
            this.maxPoints++
          }
        }
        let tempQuestionId = JSON.parse(localStorage.getItem('lecture_' + this.$route.params.id + '_' + this.$route.params.part + '_question_questionID_' + i))[0].question_id.split("/").pop();

        if (wrongAnsweredCounter > 0) {
          this.wrongAnswered.push(i);
          this.wrongQuestions++;
          this.trackAnswers(tempQuestionId, false);
        } else {
          this.trackAnswers(tempQuestionId, true);
        }
      }

      this.progressBarValue = 100 / this.maxPoints * this.reachedPoints
    },
    trackAnswers(id, result) {
      if (localStorage.getItem('userToken')) {
        let res = 1;
        if (!result) res = 0;
        axios.post(this.$store.state.trackingPath, qs.stringify({
          question_id: id,
          result: res,
          token: localStorage.getItem('userToken')
        }, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}));
      }
    },
    loadQuestions() {
      let path = this.$route.params.id
      let part = this.$route.params.part
      this.questions = JSON.parse(localStorage.getItem('lecture_' + path + '_' + part));
      this.isReady = true
    }
  },
  computed: {
    checkPosition() {
      if (this.showQuestionSet != this.questions.length) {
        return true
      }
      return false
    },
    liveTest() {
      return this.$route.params.testMode;
    }
  },
  mounted() {
    document.onkeydown = (event) => {
      if (event.keyCode == 39) {
        if (this.checkPosition) {
          this.showNext()
        }
      }
      if (event.keyCode == 37) {
        this.showPrev()
      }
    }
    let path = this.$route.params.id
    let part = this.$route.params.part

    this.$store.commit('showHeadbar', false)

    if(this.$route.params.testMode) {
      this.startNew = true;
      this.questions = JSON.parse(this.$route.params.questions);
      if (this.liveTest) {
        localStorage.setItem('lecture_' + path + '_' + part, JSON.stringify(this.questions))
      }
      this.isReady = true;
    } else {
      if (this.$route.params.reload) {
        this.startNew = true;
        this.getQuestions()
      } else {
        this.loadQuestions()
      }
    }

  },
  beforeMount() {
    this.$store.commit('resetProgressBar');
  }
}
</script>

<style lang="scss">
.questions {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  max-width: 100%;

  .progressbar-contanier {
    border-bottom: 1px solid #f0f0f0;
  }
  .questionSet {
    padding: 0 20px;
  }

  .buttons {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
  }

  .btn-arrow {
    background-color: #fff;
    border: 1px solid black;
    width: 15%;
    padding: 12px 0;
    text-transform: uppercase;

    &.w-65 {
      width: 65%;
    }
  }

  .btn-red {
    background-color: #A02330;
  }

  .btn-green {
    background-color: #008641;
  }

  .btn-c {
    font-weight: bold;
    font-size: 1.4rem;
  }

  .evaluation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.filter-white {
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(171deg) brightness(104%) contrast(101%);
}

.btn-close-modal {
  border: 1px solid black !important;
}

.btn-bubble {
  border-radius: 100% !important;
  color: #fff !important;
  border: 1px solid white !important;
  box-shadow: 0 0 4px black;
  width: 50px;
  height: 50px;
  background-color: #9F2430 !important;
}

.btn-active-question {
  border-radius: 100% !important;
  color: #9F2430 !important;
  border: 4px solid #9F2430 !important;
  background-color: #fff !important;
}

.modal-bottom {
  align-content: end !important;
}

.darkmode {
  .questions {
    color: #fff;

    .buttons {
      background-color: #000;
    }

    .btn-c {
      color: #fff;
    }
   }
}
</style>
