<template>
  <div class="picture-frame pt-2 pb-4 d-flex justify-content-center">
    <div v-if="data.active === true">
      <a @click="link" class="pointer">
        <div class="img-frame position-relative pointer">
          <img :src="getImageSource(data.imgSrc)" :alt="data.imgAlt" class="w-100">
          <div class="alternative-text">
            <strong>{{ data.name }}</strong>
          </div>
        </div>
      </a>
    </div>
    <div v-else>
      <div class="img-frame position-relative disabled-overlay">
        <img :src="getImageSource(data.imgSrc)" :alt="data.imgAlt" class="w-100">
        <div class="alternative-text">
          <strong>{{ data.name }}</strong><br>
          Berufsbild demnächst erhältlich!

          {{ data.id }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "picture-frame",
  props: ['data'],
  methods: {
    getImageSource(img_src) {
      if (img_src.includes('upload_')) {
        return this.$store.state.partImages  + 'uploads/' + img_src
      }

      return this.$store.state.imgPath+img_src
    },
    link() {
      this.$store.commit('changeArea', this.data.name);
      //this.$router.push('/lecture/' + this.data.front_id);
      this.$router.push({ name: 'parts', params: { id: this.data.front_id ,jobfieldId: this.data.id }})

    }
  }
}
</script>

<style lang="scss" scoped>
.picture-frame {
  img {
    max-width: 800px;
    border: 1px solid grey;
  }

  .disabled-overlay {

    &::before {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #fff;
      opacity: .6;
      content: ' ';
    }
  }

  .alternative-text {
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
    padding: 5px 15px;
    background-color: rgba(255,255,255,.9);
    border: 1px solid black;
  }
}
</style>
