<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img src="@/assets/icons/android-checkbox-outline.svg" class="mt-6 filter-green" style="width: 100px">
      </div>
      <div class="col-12 text-center">
        <div class="headline"><h4>Fragen beantworten</h4></div>
        <div class="middleblock mt-4">
          <div class="col-12">
            1 Antwort ist richtig.
          </div>
          <div class="col-12 d-flex text-start">
            <span v-if="checked" class="mr-2">
              <img src="@/assets/icons/android-done.svg" class="answer green-filter"  v-if="rightOne">
              <img src="@/assets/icons/android-close.svg" class="answer red-filter" v-else>
            </span>
            <input type="checkbox" id="richtig" v-model="right" class="input-box mr-5" :disabled="checked">
            <label for="richtig" class="w-100">
              richtig
            </label>
          </div>
          <hr>
          <div class="col-12 d-flex text-start">
            <span v-if="checked" class="mr-2">
              <img src="@/assets/icons/android-done.svg" class="answer green-filter" v-if="rightTwo">
              <img src="@/assets/icons/android-close.svg" class="answer red-filter" v-else>
            </span>
            <input type="checkbox" id="falsch" v-model="wrong" class="input-box mr-5" :disabled="checked">
            <label for="falsch" class="w-100">
              falsch
            </label>
          </div>
          <hr>
          <div class="col-12">
            <button class="btn btn-success text-uppercase px-3 py-2" :disabled="check" @click="checking">Lösung anzeigen</button>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="check_info" hide-footer hide-header-close hide-header centered>
      <p class="my-4">
        Das Symbol
        <img src="@/assets/icons/android-done.svg" class="green-filter" width="25px">
        zeigt Ihnen die richtige Lösung an.
        Das Symbol
        <img src="@/assets/icons/android-close.svg" class="red-filter" width="25px">
        zeigt Ihnen die falsche Lösung an
      </p>
      <div class="col-12 text-end">
        <button class="btn btn-success" @click="$bvModal.hide('check_info')">OK</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "slide_question",
  data() {
    return {
      right: false,
      wrong: false,
      checked: false
    }
  },
  methods: {
    checking() {
      this.checked = true
      this.$bvModal.show('check_info')
    }
  },
  computed: {
    rightOne() {
      if (this.right == true) {
        return true
      }
      return false
    },
    rightTwo() {
      if (this.wrong == true) {
        return false
      }
      return true
    },
    check() {
      if (this.checked) {
        return true
      }
      if ( this.right == false && this.wrong == false) {
        return true
      } else
        return false
    }
  }
}
</script>

<style scoped>
[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.mr-5 {
  margin-right: 20px;
}
.headline {
  margin-top: 120px;
}
.mt-6 {
  margin-top: 60px;
}

.green-filter {
  filter: invert(45%) sepia(93%) saturate(334%) hue-rotate(97deg) brightness(91%) contrast(86%);
}

.red-filter {
  filter: invert(47%) sepia(67%) saturate(3175%) hue-rotate(331deg) brightness(107%) contrast(92%);
}

.answer {
  width: 30px;
  border: none;
  transform: translateY(-5px);
  margin-right: 15px;
}
</style>