<template>
  <div class="progressbar">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 d-none d-md-block">
          <div class="d-flex">
            <template v-for="(question, index) in questions">
              <div class="line mx-2" :key="question"></div><div class="dot p-1" @click="$emit('goTo', index)" :class="{'active': (currentView === question), 'edited': edited.includes(index), 'wrong': wrongAnswered.includes(index), 'right': rightAnswered.includes(index)}" :key="question+5000"></div>
            </template>
          </div>
        </div>
        <div class="col-6 col-md-2">
          {{ currentView }} / {{ questions }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "progress-bar",
  props: ['currentView', 'questions'],
  data() {
    return {
      edited: this.$store.getters.getprogressBarData.editedQuestions,
      wrongAnswered: this.$store.getters.getprogressBarData.wrongAnswered,
      rightAnswered: this.$store.getters.getprogressBarData.rightAnswered
    }
  },
  computed: {
    updateProgressbar() {
      return this.$store.state.progressBarUpdate;
    }
  },
  watch: {
    updateProgressbar() {
      this.edited = "";
      this.edited = this.$store.getters.getprogressBarData.editedQuestions;
      this.wrongAnswered = this.$store.getters.getprogressBarData.wrongAnswered;
      this.rightAnswered = this.$store.getters.getprogressBarData.rightAnswered;
    }
  }
}
</script>

<style lang="scss" scoped>
.progressbar {
  .line {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background-color: black;
    &:first-child {
      width: 0;
    }
  }
  .dot {
    margin-top: 7px;
    min-width: 2px;
    height: 2px;
    border-radius: 100%;
    background-color: black;
    margin-left: 1px;
    cursor: pointer;
  }
  .active {
    position: relative;

    &:before {
      position: absolute;
      height: 10px;
      min-width: 10px;
      content: ' ';
      background-color: #fff;
      border-radius: 100%;
      border: 1px solid black;
      top: -1px;
      left: -1px;
    }
  }
  .edited {
    position: relative;

    &:before {
      position: absolute;
      height: 10px;
      min-width: 10px;
      content: ' ';
      background-color: green;
      border-radius: 100%;
      border: 1px solid green;
      top: -1px;
      left: -1px;
    }
  }
  .right {
    position: relative;

    &:before {
      position: absolute;
      height: 10px;
      min-width: 10px;
      content: ' ';
      background-color: green;
      border-radius: 100%;
      border: 1px solid green;
      top: -1px;
      left: -1px;
    }
  }
  .wrong {
    position: relative;

    &:before {
      position: absolute;
      height: 10px;
      min-width: 10px;
      content: ' ';
      background-color: red;
      border-radius: 100%;
      border: 1px solid #000;
      top: -1px;
      left: -1px;
    }
  }
}

.darkmode {
  .progressbar {
    .line {
      background-color: #fff;
    }
    .dot {
      border: 1px solid #fff;
    }
  }
}
</style>